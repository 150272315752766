#popup_detail_plan_job {
  .modal-dialog {
    max-width: 1172px;

    .detail_plan {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 44px;

      &:first-child span {
        border-top: 1px solid #D9D9D9;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        width: 189px;
        min-height: 44px;
        background: #ECF0FF;
        border-bottom: 1px solid white;
      }

      span {
        width: 930px;
        min-height: 44px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 0 16px;
        border-bottom: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;
      }
    }
  }
}

#add_or_edit_plan_job {
  .step_header {
    margin-bottom: 12px;

    .step_ {
      width: 30px;
      height: 30px;
      display: flex;
      border-radius: 50%;
      position: relative;
      align-items: center;
      justify-content: center;
      border: 1.88542px solid #D1D5DB;

      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #D1D5DB;
        display: inline-block;
      }

      &:nth-child(2) {
        margin: 0 75px;
      }
    }

    .step_.active {
      border: 1.88542px solid #29AB56;
    }

    .step_.check {
      background-color: #29AB56;
      border: 1.88542px solid #29AB56;

      i {
        color: white;
        font-size: 14px;
      }
    }

    .line {
      top: 13px;
      right: -78px;
      position: absolute;
      width: 78px;
      height: 1.89px;
      background: #D1D5DB;
    }

    .line.active {
      background: #29AB56;
    }
  }

  .step_two {
    margin: 0 !important;
    .col-md-8, .col-md-4 {
      background: white;
      padding: 16px 24px;
      border: 1px solid #29AB56;

      h5 {
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        margin-bottom: 16px;
      }

      span.text{
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        color: #333333;
        margin-top: 14px;
      }
    }

  }

  .table td{
    padding: 1rem 0.9375rem;
    span{
      display: inline-block;
      overflow: hidden;
      max-width: 200px;
      font-size: 14px;
      height: 22px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  .table_plan_job{
    width: 100%;
    padding: 0;
    min-height: 220px;
    max-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    tbody tr td:last-child {
      width: 8%;
    }
  }

  input{
    border: 1px solid #dedede;
    &:focus-visible, &:focus{
      outline: none !important;
    }
  }

  .step3 input{
    width: 140px;
    height: 26px;
    padding: 0 16px;
    text-align: center;
    border-radius: 2px;
  }
  .input_time{
    input{
      width: 102px;
      height: 24px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      border: 1px solid #CDCDCD;
      &:focus-visible, &:focus{
        outline: none !important;
      }
    }
  }
}
