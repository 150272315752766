.btn_detail__tracking {
  border: none;
  outline: none;
  background: transparent;
}
.back {
  display: flex;
  margin-bottom: 24px;
  &:hover {
    opacity: 0.8;
  }

  i {
    margin-right: 8px;
    color: #5C5C5C;
    font-size: 20px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
  }
}