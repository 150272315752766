.info_tracking_breed {
  padding: 22px 95px 40px;

  .btn_print {
    border: 1px solid #00953D;
    color: #00953D;
  }

  .logo {
    margin-bottom: 32px;
  }

  .container {
    max-width: 1239px !important;
  }

  .qr_code_mb {
    display: none !important;
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #00953D;
    margin-bottom: 16px;
  }

  .trackingImage {
    display: flex;
    justify-content: flex-end;

    img {
      width: 516px;
      height: 305px;
      object-fit: cover;
      border: 1px solid #00953D;
      border-radius: 3px;
    }
  }

  .info_ {
    img{
      width: 100%;
    }
  }

  .USING {
    width: 516px;
    font-weight: 500;
    font-size: 14px;
    color: #00953D;
  }

  .EVICTION {
    width: 516px;
    font-weight: 500;
    font-size: 14px;
    color: #5C5C5C;
  }

  .EXPIRED {
    width: 516px;
    font-size: 14px;
    font-weight: 500;
    color: #FF3030;
  }

  .info_unit {
    width: 100%;
    margin-bottom: 45px;

    .info_text {
      width: 100%;
      min-height: 38px;
      padding: 8px 16px;
      display: flex;
      border-radius: 8px;

      label {
        margin: 0;
        width: 172px;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        min-height: 18px;
        color: #515154;
      }

      span {
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        color: #000000;
        width: 396px;
      }

      &:first-child, &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9) {
        background: #F5F5F7;
      }
    }
  }

  .scroll_images {
    width: 100%;
    height: 280px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 100%;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }


    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .list_images {
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;

      .item {
        width: 254px;
        height: 260px;
        border: 1px solid #57902B;
        border-radius: 3px;
        margin-right: 16px;

        img {
          width: 252px;
          height: 258px;
          object-fit: cover;
        }
      }
    }
  }

  .scroll_certificate {
    width: 100%;
    height: 280px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 100%;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }


    .list_certificate {
      width: 100%;
      max-width: 2600px;
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;

      .item {
        width: 254px;
        height: 260px;
        margin-right: 24px;
        border: 1px solid #57902B;
        border-radius: 3px;
        background-color: white;

        img {
          width: 252px;
          height: 258px;
          object-fit: cover;
        }
      }
    }
  }

  .post_product {
    .list_post {
      width: 100%;
      height: 100%;
      flex-wrap: wrap;

      .post {
        width: 32%;
        height: 100%;

        &:hover {
          h4 {
            color: #F69320;
          }
        }

        .images {
          width: 100%;
          height: 245px;
          margin-bottom: 24px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content {
          width: 100%;

          h4 {
            font-size: 18px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-weight: 700;
            margin-bottom: 8px;
          }

          span {
            font-size: 16px;
            color: #999999;
          }
        }
      }
    }
  }

  .review {
    border-radius: 4px;
    border: 1px solid #E5E5E5;

    .rating {
      width: 380px;
      height: 200px;
      border-right: 1px solid #EFEFEF;

      .rate_ {
        width: 292px;

        .title {
          width: 292px;
          justify-content: center;
          margin-bottom: 20px;

          h3 {
            color: #222222;
            font-size: 30px;
            font-weight: 500;
            font-family: Roboto-Regular;
          }

          i {
            color: #FF9F00;
            font-size: 18px;
            margin-right: 2px;
          }

          i.active {
            color: #FF9F00;
          }

          p {
            color: #000000;
            margin-bottom: 0;
            font-size: 14px;
            margin-left: 10px;
          }
        }

        .content {
          .val {
            font-size: 14px;
            color: #86868B;
          }

          i {
            font-size: 16px;
            color: #86868B;
            margin-left: 6px;
          }

          .progress {
            width: 210px;
            height: 8px;
            margin: 0 12px;
            background: #EEEEEE;

            .progress-bar.rate {
              background: #FFBD0A !important;
            }

          }

          .total {
            color: #000000;
            font-size: 14px;
          }
        }
      }
    }

    .comment {
      width: 638px;

      .title {
        width: 100%;
        height: 32px;
        margin-bottom: 10px;

        input {
          width: 220px;
          border: none;
          padding: 0 12px;
          font-size: 14px;
        }

        .right {
          span {
            color: #D0D0D0;
            font-size: 14px;
            display: inline-block;
            margin-right: 6px;
          }

          i {
            color: #D0D0D0;
            font-size: 18px;
            margin-left: 2px;
          }

          i.active {
            color: #feb700;
          }
        }
      }

      .content {
        width: 100%;
        border-radius: 2px;
        border: 1px solid rgba(45, 57, 76, 0.1);

        textarea {
          width: 100%;
          padding: 12px;
          font-size: 14px;
          border: none;
        }

        .footer {
          width: 100%;
          padding: 12px;
          background-color: rgba(45, 57, 76, 0.1);;

          button {
            width: 68px;
            height: 32px;
            font-size: 14px;
            color: white;
            font-weight: 500;
            border: none;
            background-color: #00953D;
            border-radius: 2px;

            &:disabled {
              opacity: 0.4;
            }
          }
        }
      }
    }

    .list_comment {
      margin: 16px 0;

      .comment_ {
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #EFEFEF;

        &:last-child {
          border: none;
        }

        .title {
          margin-bottom: 10px;

          .avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #F69320;
            color: white;
            font-size: 15px;
            font-weight: 500;
          }

          .name {
            font-size: 15px;
            color: #444444;
            margin: 0 10px;
            font-weight: 600;
          }

          i {
            font-size: 16px;
            color: #a0a0a2;
          }

          .active {
            color: #feb700;
          }
        }

        .content {
          margin-bottom: 10px;

          span {
            font-size: 13px;
            color: #515154;
          }
        }

        .time {
          color: #888888;
          font-size: 13px;
        }

        .repComment {
          width: 100%;
          padding: 16px;
          background-color: #F5F5F7;

          .title {
            margin-bottom: 10px;

            .avatar {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: #2D5BFF;
              color: white;
              font-size: 15px;
              font-weight: 500;
            }

            .name {
              font-size: 15px;
              color: #444444;
              margin: 0 10px;
              font-weight: 600;
            }
          }

          .content {
            margin-bottom: 0;

            span {
              font-size: 13px;
              color: #515154;
            }
          }
        }
      }
    }

    button.readMore {
      width: 280px;
      height: 40px;
      font-size: 14px;
      color: #000000;
      border-radius: 8px;
      border: 1px solid #000000;
      background-color: white;

      &:disabled {
        opacity: 0.4;
      }
    }

  }
}

@media print {
  body * {
    visibility: hidden;
  }
  button.btn_print {
    visibility: hidden;
  }
  .info_tracking_breed * {
    visibility: visible;
  }
}

@media screen and (max-width: 1024px) {
  .info_tracking_breed {
    .info_ {
      width: 100%;
      display: inline-block !important;

      .info_unit {
        padding: 0 !important;
        width: 100% !important;
      }

      .trackingImage {
        padding: 0 !important;
        width: 100% !important;
        justify-content: center;
        margin-bottom: 45px;

        img {
          width: 100%;
          height: 240px;
        }
      }
    }
  }

}

@media screen and (max-width: 540px) {
  .info_tracking_breed {
    padding: 32px 25px;

    .info_ {
      .trackingImage {
        margin-bottom: 12px;
      }
    }

    .info_unit .info_text {
      align-items: start;
      padding: 8px 12px;

      span {
        width: 230px;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .USING, .EVICTION, .EXPIRED {
      width: 100%;
      margin-bottom: 18px;
    }

    .list_images, .list_certificate {
      width: 1350px;

      .item {
        width: 48%;
        height: 180px;
      }
    }

    .post_product .list_post {
      .post {
        width: 100% !important;
        margin-bottom: 32px;

        .images {
          height: 180px !important;
          margin-bottom: 16px;
        }

        .content {
          h4 {
            font-size: 16px;
          }

          span {
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }

    .review {
      margin: 0 !important;
      border-radius: 0;
      border: none;
      padding: 8px !important;

      .rating {
        width: 100%;
        height: 200px;
        border-right: none;

        .rate_ {
          width: 100%;

          .title {
            width: 100%;
          }

          .content {
            .progress {
              width: 75%;
            }

          }
        }
      }

      .comment {
        width: 100%;

        .title {
          height: auto;
          flex-wrap: wrap-reverse;

          input {
            width: 100%;
            height: 32px;
            border: 1px solid #cdcdcd;
          }

          .right {
            width: 100%;
            margin-bottom: 10px;
            justify-content: center;

            span {
              color: #D0D0D0;
              font-size: 14px;
              display: inline-block;
              margin-right: 6px;
            }

            i {
              color: #D0D0D0;
              font-size: 18px;
              margin-left: 2px;
            }

            i.active {
              color: #feb700;
            }
          }
        }

        .content {
          width: 100%;
          border-radius: 2px;
          border: 1px solid rgba(45, 57, 76, 0.1);

          textarea {
            width: 100%;
            padding: 12px;
            font-size: 14px;
            border: none;
          }

          .footer {
            width: 100%;
            padding: 12px;
            background-color: rgba(45, 57, 76, 0.1);;

            button {
              width: 68px;
              height: 32px;
              font-size: 14px;
              color: white;
              font-weight: 500;
              border: none;
              background-color: #00953D;
              border-radius: 2px;

              &:disabled {
                opacity: 0.4;
              }
            }
          }
        }
      }

      .list_comment {
        margin: 16px 0;

        .comment_ {
          width: 100%;
          padding: 16px 0;
          border-bottom: 1px solid #EFEFEF;

          &:last-child {
            border: none;
          }

          .title {
            margin-bottom: 10px;

            .avatar {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: #F69320;
              color: white;
              font-size: 15px;
              font-weight: 500;
            }

            .name {
              font-size: 15px;
              color: #444444;
              margin: 0 10px;
              font-weight: 600;
            }

            i {
              font-size: 16px;
              color: #a0a0a2;
            }

            .active {
              color: #feb700;
            }
          }

          .content {
            margin-bottom: 10px;

            span {
              font-size: 13px;
              color: #515154;
            }
          }

          .time {
            color: #888888;
            font-size: 13px;
          }

          .repComment {
            width: 100%;
            padding: 16px;
            background-color: #F5F5F7;

            .title {
              margin-bottom: 10px;

              .avatar {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: #2D5BFF;
                color: white;
                font-size: 15px;
                font-weight: 500;
              }

              .name {
                font-size: 15px;
                color: #444444;
                margin: 0 10px;
                font-weight: 600;
              }
            }

            .content {
              margin-bottom: 0;

              span {
                font-size: 13px;
                color: #515154;
              }
            }
          }
        }
      }

      button.readMore {
        width: 280px;
        height: 40px;
        font-size: 14px;
        color: #000000;
        border-radius: 8px;
        border: 1px solid #000000;
        background-color: white;

        &:disabled {
          opacity: 0.4;
        }
      }

    }
  }

}

@media screen and (max-width: 414px) {
  .info_tracking_breed {
    padding: 24px 6px;

    .logo img {
      width: 100px;
    }

    .qr_code_mb {
      display: flex !important;
    }

    h2 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 16px;
    }

    .info_unit .info_text {
      label {
        width: 146px;
        padding-right: 18px;
      }

      span {
        width: 196px;
      }
    }
  }

}