.diary {
  width: 100%;

  .tab_header {
    width: 100%;
    height: 62px;

    .tab {
      width: 33.333%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #FFFFFF;
      border: 1px solid #D7D7D7;

      span {
        color: #009444;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
      }
    }

    .tab.active {
      background: #009444;
      border: 1px solid #009444;

      span {
        color: white;
      }
    }

  }

  .progress {
    width: 364px;
    background: #D9D9D9;

    .progress-bar.PREPARE {
      background: #B8B8B8 !important;
    }

    .progress-bar.HARVEST {
      background: #00AA24 !important;
    }

    .progress-bar.PLANT {
      background: #F48922 !important;
    }

    .progress-bar.CARE {
      background: #75C124 !important;
    }
  }

  .table_plan_job {
    width: 100%;
    padding: 0;
    min-height: 220px;
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    tbody tr td:last-child {
      width: 8%;
    }
  }

  .schedule_diary {
    .calendar_plan {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #E7E8EA;

      .header_top {
        width: 100%;
        height: 80px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-weight: 500;
          font-size: 28px;
          line-height: 38px;
          color: #000000;
          margin: 0;
        }

        .filter_month {
          width: 245px;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #D9D9D9;
          border-radius: 8px;

          .line {
            width: 1px;
            height: 35px;
            display: inline-block;
            border-right: 1px solid #D9D9D9;
          }

          button {
            width: 49.8%;
            height: 100%;
            border: none;
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            color: #000000;
            background-color: transparent;
          }
        }
      }

      .list_week_day {
        width: 100%;
        display: flex;
        align-items: center;

        .day {
          width: 14.266%;
          height: 52px;
          font-weight: 500;
          font-size: 16px;
          text-transform: uppercase;
          color: #000000;
          border: 1px solid #E7E8EA;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            border-left: none;
          }
        }
      }

      .list_month_day {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .day {
          width: 14.266%;
          height: 120px;
          padding: 10px;
          border: 1px solid #E7E8EA;

          p {
            font-weight: 600;
            font-size: 26px;
            line-height: 31px;
          }
        }

        .day_active {
          background: #0E9435;

          p {
            color: white;
          }

          span {
            display: block;
            cursor: pointer;
            width: 100%;
            margin-bottom: 6px;
            font-weight: 500;
            font-size: 14px;
            text-decoration: underline;
            line-height: 16px;
            text-transform: capitalize;
            color: #FFFFFF;
          }
        }
      }
    }

  }

  .btn-finish-plan {
    width: auto;
    height: 40px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00953D;
    border-radius: 2px;
    border: none;

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }

  .fc-toolbar-title {
    text-align: center;
    float: none;
    text-transform: capitalize;
  }

  table.fc-scrollgrid {
    tr > th {
      padding: 0 !important;
      background-color: white;

      table.fc-col-header {
        margin-right: 0 !important;

        tr > th {
          padding: 20px 0 !important;
        }
      }
    }
  }

  tbody {
    .fc-daygrid-day-top {
      padding: 10px 15px;

      .fc-daygrid-day-number {
        font-weight: 600;
        color: #000000;
        font-size: 22px;
      }
    }

    .fc-daygrid-event{
      cursor: pointer;
      background-color: #EDFFF5 !important;

      .fc-event-title {
        color: #00953D !important;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .fc-daygrid-day-frame{
      background-color: white !important;
    }
  }

  .fc-event-start{
    border-left: 2px solid #00953D !important;
  }

  .fc-h-event{
    background-color: #EDFFF5 !important;

    .fc-event-title {
      width: 100%;
      color: #00953D !important;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .fc-highlight{
    background: rgba(188, 232, 241, 0.3) !important;
    background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3)) !important;
  }


  .fc-header-toolbar{
    width: 100%;
    .fc-toolbar-chunk{
      width: 33.333%;
      .fc-button-group {
        padding-right: 20px;
        float: right;
        .fc-button{
          width: 112px;
          height: 50px;
          border: 1px solid #D9D9D9;
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          &:hover{
            border: 1px solid #D9D9D9;
            color: #000000;
            background-color: transparent;
          }
          &:focus{
            outline: none !important;
            box-shadow: none;
          }
        }
      }
      .fc-button-primary:disabled{
        background-color: #00953D !important;
        border: none;
        color: white;
      }
    }
  }

}

#add_edit_job{
  .table td{
    padding: 1rem 0.9375rem;
    span{
      display: inline-block;
      overflow: hidden;
      max-width: 200px;
      font-size: 14px;
      height: 22px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  .table_job{
    width: 100%;
    padding: 0;
    min-height: 220px;
    max-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    tbody tr td:last-child {
      width: 8%;
    }

    input{
      border: 1px solid #dedede;
      &:focus-visible, &:focus{
        outline: none !important;
      }
    }

    input{
      width: 140px;
      height: 26px;
      padding: 0 16px;
      text-align: center;
      border-radius: 2px;
    }
  }


  .list_image_job {
    width: 100%;
    .list_ {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;

      .upload {
        width: 80px;
        height: 80px;
        display: flex;
        margin-right: 12px;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer !important;
        border: 1px solid #747474;
        border-radius: 2px;

        input {
          width: 80px;
          height: 80px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;
        }
      }

      .item {
        width: 80px;
        height: 80px;
        margin-right: 12px;
        position: relative;
        border: 1px solid #00953D;
        border-radius: 2px;

        .img {
          width: 78px;
          height: 78px;
          object-fit: cover;
        }

        .close_item {
          top: -12px;
          right: -12px;
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }

}

#popup_detail_job{

  .table_job{
    width: 100%;
    border: 1px solid #A6A6A6;
    .row_job{
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #CFCFCF;
      &:last-child{
        border: none
      }
      label{
        width: 189px;
        height: 100%;
        display: flex;
        margin: 0;
        font-size: 14px;
        align-items: center;
        padding: 0 12px;
        background-color: #F2F5FF;
      }
      span{
        display: flex;
        align-items: center;
        padding: 0 12px;
        width: calc(100% - 189px);
        min-height: 44px;
        height: 100%;
      }
    }
    .row{
      border-bottom: 1px solid #CFCFCF;
      label.col-2{
        width: 189px !important;
        max-width: 100% !important;
        flex: 0 0 189px;
        display: block;
        margin: 0;
        font-size: 14px;
        padding: 8px 12px;
        background-color: #F2F5FF;
      }
      div.col-10{
        display: block;
        padding: 8px 12px;
        width: calc(100% - 189px);
        flex: 0 0 calc(100% - 189px);
        max-width: 100% !important;
        height: 100%;
      }
    }
  }
  .list_image_job{
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
    &:last-child{
      margin-right: 0;
    }

    img{
      width: 120px;
      height: 120px;
      border-radius: 8px;
      margin-right: 12px;
      border: 1.5px solid #29AB56;
    }
  }
}
