
.planning {
  width: 100%;

  .tab_header {
    width: 100%;
    height: 72px;

    .w-50 {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #FFFFFF;
      text-decoration: none;
      border: 1px solid #D7D7D7;

      span {
        color: #009444;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
      }
    }

    .w-50.active {
      background: #009444;
      border: 1px solid #009444;

      span {
        color: white;
      }
    }

  }

  #add_edit_plan_two {

    .tab_filter {
      width: 100%;
      height: 100%;
      margin-bottom: 16px;
      padding: 10px 26px;
      border: 1px solid #29AB56;

      img {
        margin-right: 8px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }

      .form-select {
        width: 100%;
        padding: 0 16px;
        height: 36px;
        font-weight: 400;
        font-size: 14px;
        border: 1px solid #1A1A1A;
        border-radius: 2px;
      }
    }

    .table_land{
      width: 100%;
      height: 260px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .expectedOutput {
      width: 100%;
      height: 45px;
      margin: 12px 0;
      border: 1px solid #7C7B7B;
      border-radius: 2px;
      padding: 0 28px;
      span{
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        display: inline-block;
        margin-right: 40px;
      }
      input{
        width: 185px;
        height: 30px;
        padding: 0 16px;
        text-align: left;
        font-size: 14px;
        border: 1px solid #626262;
        &:focus-visible{
          outline: none;
        }
        &::placeholder{
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #BEBEBE;
        }
      }
    }

  }


  .header_plan_job{
    margin-top: 12px;
    label{
      margin: 0 6px 0 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #000000;
    }
    span{
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
      color: #000000;
    }
    .info_plan{
      width: 100%;
      height: 24px;
      margin-bottom: 12px;
      .info{
        height: 24px;
        padding: 0 16px;
        border-right: 1px solid #009444;
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          border-right: 0;
        }
      }
    }
  }
}