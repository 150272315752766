
.questions {
  padding-top: 4px;
  border-left: 1px solid #f3f3f3;

  .list-question {
    width: 100%;
    max-height: calc(100vh - 360px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 28px 18px 0;

    .item {
      width: 100%;
      min-height: 96px;
      padding: 16px 32px 16px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1.5px solid #D7D7D7;

      &:last-child {
        border: none;
      }

      .info_question {
        .avatar, .no_avatar{
          width: 42px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 15px;

          img {
            width: 42px;
            height: 42px;
            object-fit: cover;
            border-radius: 50%;
          }
          i{
            font-size: 28px;
            color: #7C7B7B;
          }
        }

        .no_avatar{
          background: #F9F9F9;
        }

        .title {
          font-weight: 500;
          font-size: 18px;
          color: #00953D;
          margin-bottom: 6px;
        }
      }

      span.text {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        margin-right: 12px;
        color: #7C7B7B;

        &:last-child {
          margin-right: 0;
        }
      }

      span.value {
        font-weight: 500;
        font-size: 16px;
        color: #7C7B7B;
        display: inline-block;
        margin-left: 6px;
      }
    }
  }
}

.detail_question {
  scroll-behavior: smooth;
  .header_top {
    width: 100%;
    height: 72px;
    padding: 0 12px;

    .back {
      width: 114px;
      height: 34px;
      text-decoration: none;
      background: transparent;
      border: 1px solid #747474;
      border-radius: 2px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #747474;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 18px;
        color: #747474;
        margin-right: 6px;
      }
    }
  }

  .breadcrumb {
    display: flex;
    align-items: center;
    border: none;
    padding: 12px;
    background-color: white;
    margin: 0;

    i {
      color: #747474;
      font-size: 20px;
      margin: 0 4px;
    }

    img {
      margin-right: 6px;
    }

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      color: #747474;
    }
  }

  .question {
    width: 100%;
    padding: 18px 12px;
    background-color: white;

    .info_user {
      width: 86px;
      height: 76px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .avatar {
        width: 50px;
        height: 50px;
        margin-bottom: 12px;

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .role {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: rgba(30, 30, 30, 0.6);
      }
    }

    .action {
      width: 55px;
      display: flex;
      justify-content: center;

      i {
        width: 24px;
        height: 24px;
        color: #7C7B7B;
      }

      .dropdown {
        height: 40px;
      }

      .dropdown-menu {
        padding: 4px 0;
        min-width: 120px !important;
        width: 120px !important;
        left: -40px !important;

        .dropdown-item {
          font-weight: 500;
          font-size: 14px;
          height: 32px;
          color: #00953D;
          display: flex;
          padding: 0 16px;
          border: none;
          background-color: transparent;
          align-items: center;
          border-bottom: 1px solid rgba(232, 234, 236, 0.78);
          &:last-child{
            border: none;
          }
          img {
            margin-right: 8px;
          }
        }
      }
    }

    .content {
      width: calc(100% - 155px);

      .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 16px;
        color: #00953D;
      }

      .images {
        width: 100%;
        .image {
          width: 680px;
          height: 382px;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .list_img{
          width: 400px;
          display: flex;
          flex-wrap: wrap;
          .img_{
            width: 185px;
            height: 185px;
            margin-right: 12px;
            margin-bottom: 12px;
            border: 1px solid rgba(153, 160, 178, 0.3);
            &:last-child, &:nth-child(2n){
              margin-right: 0;
            }
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .description {
        font-weight: 400;
        font-size: 14px;
        margin: 16px 0;
        color: #1E1E1E;
      }

      .count_comment {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      .time, .name {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #7C7B7B;
      }
    }
  }

  .comments{
    width: 100%;
    background-color: white;

    .send_comment{
      width: 100%;
      display: flex;
      padding: 18px 12px;

      .info_user {
        width: 86px;
        height: 76px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .avatar, .no_avatar{
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-bottom: 12px;

          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
          }
          i{
            font-size: 30px;
            color: #7C7B7B;
          }
        }
        .no_avatar{
          background: #F9F9F9;
        }

        .role {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: rgba(30, 30, 30, 0.6);
        }
      }

      .content{
        width: calc(100% - 155px);

        .bt_comment{
          width: 100%;
          padding: 16px;
          background: #F5F6F7;
          border: 1px solid rgba(45, 57, 76, 0.03);

          .list_image_{
            width: calc(100% - 260px);
            height: 120px;
            .list_ {
              width: 100%;
              height: 120px;
              overflow: hidden;
              display: flex;
              align-items: center;

              .item {
                width: 120px;
                height: 120px;
                margin-right: 12px;
                position: relative;
                border: 1px solid #00953D;
                border-radius: 6px;

                .img {
                  width: 118px;
                  height: 118px;
                  object-fit: cover;
                  border-radius: 6px;
                }

                .close_item {
                  top: 5px;
                  right: 5px;
                  position: absolute;
                  cursor: pointer;
                }
              }
            }
          }

          .bt_send{
            width: 128px;
            height: 36px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            background: #00953D;
            border-radius: 4px;
            border: none;
            i{
              font-size: 18px;
            }
          }
        }
      }

      .tox-tinymce{
        border-radius: 6px 6px 0 0;
      }

    }
    .list_comment{
      width: 100%;
      .item{
        width: 100%;
        height: 100%;
        display: flex;
        margin-bottom: 12px;
        padding: 18px 12px;

        .info_user {
          width: 86px;
          height: 76px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          .avatar, .no_avatar{
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-bottom: 12px;

            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
              border-radius: 50%;
            }
            i{
              font-size: 30px;
              color: #7C7B7B;
            }
          }
          .no_avatar{
            background: #F9F9F9;
          }

          .role {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(30, 30, 30, 0.6);
          }
        }

        .action {
          width: 55px;
          height: 65px;
          display: flex;
          justify-content: center;

          i {
            width: 24px;
            height: 24px;
            color: #7C7B7B;
          }

          .dropdown {
            width: 100%;
            height: 25px;
            text-align: center;
          }

          .dropdown-menu {
            padding: 4px 0;
            min-width: 120px !important;
            width: 120px !important;
            left: -24px !important;

            .dropdown-item {
              font-weight: 500;
              font-size: 14px;
              height: 32px;
              color: #00953D;
              display: flex;
              padding: 0 16px;
              align-items: center;
              background-color: transparent;
              border-bottom: 1px solid rgba(232, 234, 236, 0.78);
              &:last-child{
                border: none;
              }
              img {
                margin-right: 8px;
              }
            }
          }
        }

        .content {
          width: calc(100% - 155px);
          .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
            color: #1E1E1E;
            span{
              font-size: 14px;
              color: #1E1E1E;
              font-weight: 500;
              p{
                margin-bottom: 0;
              }
            }
          }

          .time, .name {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #7C7B7B;
          }
        }

        .list_attachFiles{
          width: 100%;
          height: 120px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;

          .item_img{
            width: 120px;
            height: 120px;
            border-radius: 4px;
            margin-right: 12px;
            img{
              width: 118px;
              height: 118px;
              object-fit: cover;
            }
          }
        }
      }
      .item.active{
        background: #ECFFF5;
      }
    }

  }

  .no_content{
    padding: 120px;
    img{
      margin-bottom: 35px;
    }
    p{
      width: 100%;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #888888;
    }
  }
}

.list_image{
  margin: 0;
  width: calc(100% - 246px);
  height: 135px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 100%;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .list_ {
    height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .item {
      width: 120px;
      height: 120px;
      margin-right: 12px;
      position: relative;
      border: 1px solid #00953D;
      border-radius: 6px;

      .img {
        width: 118px;
        height: 118px;
        object-fit: cover;
        border-radius: 6px;
      }

      .close_item {
        top: 5px;
        right: 5px;
        position: absolute;
        cursor: pointer;
      }
    }
  }
}

.upload {
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer !important;
  i{
    color: rgba(0, 0, 0, 0.54);
  }
  input {
    width: 20px;
    height: 20px;
    opacity: 0;
    left: 0;
    z-index: 1000;
    cursor: pointer !important;
    overflow: hidden;
    position: absolute;

    &::-webkit-file-upload-button {
      width: 20px;
      height: 20px;
      opacity: 0;
      left: 0;
      z-index: 1000;
      cursor: pointer !important;
      position: absolute;
    }
  }
}

.bt-send-request {
  width: 180px;
  height: 40px;
  border: none;
  background: #29AB56;
  border-radius: 2px;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-decoration: none;
  &:hover {
    color: #FFFFFF;

    background: #21984A;
  }

  i{
    margin-left: 6px;
  }
}