@import "config.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-30{
  opacity: .3;
}

.disabled{
  cursor: no-drop;
}

.full_screen{
  position: absolute;
  z-index: 1200;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 !important;
}
