#qr_code_tracking {
  .form {
    width: 100%;
    padding: 12px 28px 20px;
    background: #F8FDFF;

    .step_2 {
      width: 100%;

      .link_download_qr {
        width: 100%;
        padding: 10px 20px 20px;
        margin: 8px 0 16px;
        border: 1px solid rgba(0, 149, 61, 0.46);

        .action_ {
          input {
            width: 89%;
            height: 34px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 12px;
            margin-right: 12px;
            border: 1px solid #7C7B7B;
            border-radius: 2px;
          }

          button {
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}

#history_qr {
  .form {
    width: 100%;
    display: inline-block;
    padding: 12px 28px 20px;
    background: #F8FDFF;

    .list_history {
      width: 100%;
      height: 312px;
      overflow-y: scroll;
      padding: 10px 20px;
      margin: 8px 0 16px;
      border: 1px solid rgba(0, 149, 61, 0.46);

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #7C7B7B;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .isExpired {
        opacity: 0.4;
      }

      .item {
        width: 100%;
        height: 58px;
        margin-bottom: 16px;

        span {
          font-size: 12px;
        }

        .action_ {
          margin-top: 6px;

          input {
            width: 89%;
            height: 34px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 12px;
            margin-right: 12px;
            border: 1px solid #7C7B7B;
            border-radius: 2px;
          }

          button {
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}
