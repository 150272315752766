.nav_notification {
  width: 440px;
  padding: 0;
  height: calc(100vh - 98px);
  background-color: white;
  transform: translate3d(-392px, 63px, 0px) !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2) !important;

  .title {
    width: 440px;
    height: 45px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #1A1F36;
    }

    .check {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      padding: 0;
      &:disabled{
        span {
          color: #D7D7D7;
        }

        i {
          color: #D7D7D7;
        }
        cursor: no-drop;
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1A1F36;
      }

      i {
        color: #00953D;
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }

  .list_notice {
    width: 100%;
    height: calc(100% - 45px);
    border-radius: 8px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #7C7B7B;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .noti {
      width: 100%;
      height: 108px;
      padding: 16px;
      display: flex;
      border-bottom: 1px solid #E4E8EE;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: #F4FFF9;
      }

      .avatar {
        width: 32px;
        height: 32px;
        margin-right: 16px;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .content {
        width: calc(100% - 48px);

        .text {
          font-weight: 600;
          font-size: 14px;
          height: 40px;
          line-height: 20px;
          color: #1A1F36;
          margin-bottom: 16px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .time {
          font-size: 14px;
          line-height: 20px;
          color: #A5ACB8;
        }
      }

      .read{
        width: 18px;
        height: 18px;
        right: 9px;
        top: 50px;
        background: #90CDF4;
        border-radius: 50%;
        border: 1px solid #4299E1;
        position: absolute;
      }

    }

  }

  .no_content {
    width: 100%;
    padding-top: 80px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      margin: 16px 0 0;
      text-align: center;
      color: #00953D;
    }
  }
}