$colors: (
  'ffffff': #ffffff,
  'EBEBF0': #EBEBF0,
  '061020': #061020,
  '00953D': #00953D,
  'F3F4F5': #F3F4F5,
  '7C7B7B': #7C7B7B,
  'eff0f1': #eff0f1,
  'F6F8FF': #F6F8FF,
  'F3F3F3': #F3F3F3,
  'FF3030': #FF3030,
  'D7D7D7': #D7D7D7,
  'F97414': #F97414,
);

@each $name,
$value in $colors {
  .border-#{$name} {
    border: 1px solid $value;
  }

  .border-left-#{$name} {
    border-left: 1px solid $value;
  }

  .border-right-#{$name} {
    border-right: 1px solid $value;
  }

  .border-top-#{$name} {
    border-top: 1px solid $value;
  }

  .border-bottom-#{$name} {
    border-bottom: 1px solid $value;
  }

  .color-#{$name} {
    color: $value;
  }

  .fill-#{$name} {
    fill: $value;
  }

  .hover-color-#{$name} {
    &:hover {
      color: $value;
    }
  }

  .hover-bg-#{$name} {
    &:hover {
      background-color: $value !important;
    }
  }

  .bg-#{$name} {
    background-color: $value !important;
  }

}

@for $i from 0 through 1920 {
  $px: $i + px;

  .m-#{$px} {
    margin: $px !important;
  }

  .mt-#{$px} {
    margin-top: $px !important;
  }

  .mr-#{$px} {
    margin-right: $px !important;
  }

  .mb-#{$px} {
    margin-bottom: $px !important;
  }

  .ml-#{$px} {
    margin-left: $px !important;
  }

  .mx-#{$px} {
    margin-left: $px !important;
    margin-right: $px !important;
  }

  .my-#{$px} {
    margin-top: $px !important;
    margin-bottom: $px !important;
  }

  .p-#{$px} {
    padding: $px !important;
  }

  .pt-#{$px} {
    padding-top: $px !important;
  }

  .pr-#{$px} {
    padding-right: $px !important;
  }

  .pb-#{$px} {
    padding-bottom: $px !important;
  }

  .pl-#{$px} {
    padding-left: $px !important;
  }

  .px-#{$px} {
    padding-left: $px !important;
    padding-right: $px !important;
  }

  .py-#{$px} {
    padding-top: $px !important;
    padding-bottom: $px !important;
  }

  .h-#{$px} {
    height: $px !important;
  }

  .min-h-#{$px} {
    min-height: $px !important;
  }

  .max-h-#{$px} {
    max-height: $px !important;
  }

  .calc-h-#{$px} {
    height: calc(100% - #{$px}) !important;
  }

  .calc-h-vh-#{$px} {
    height: calc(100vh - #{$px}) !important;
  }

  .calc-max-h-vh-#{$px} {
    max-height: calc(100vh - #{$px}) !important;
  }

  .calc-w-vw-#{$px} {
    width: calc(100vw - #{$px}) !important;
  }

  .calc-max-w-vw-#{$px} {
    max-width: calc(100vw - #{$px}) !important;
  }

  .w-#{$px} {
    width: $px !important;
  }

  .min-w-#{$px} {
    min-width: $px !important;
  }

  .max-w-#{$px} {
    max-width: $px !important;
  }

  .calc-w-#{$px} {
    width: calc(100% - #{$px}) !important;
  }

  .fs-#{$px} {
    font-size: $px !important;
  }

  .fw-#{$i} {
    font-weight: $i !important;
  }

  .gap-#{$px} {
    gap: $px !important;
  }

  .col-gap-#{$px} {
    column-gap: $px !important;
  }

  .row-gap-#{$px} {
    row-gap: $px !important;
  }

  .bottom-#{$px} {
    bottom: $px !important;
  }

  .line-height-#{$px} {
    line-height: $px !important;
  }

  .left-#{$px} {
    left: $px !important;
  }

  .right-#{$px} {
    right: $px !important;
  }

  .top-#{$px} {
    top: $px !important;
  }

  .rounded-#{$px} {
    border-radius: $px !important;
  }

  .flex-#{$px} {
    flex: $i !important;
  }

  .flex-#{$i} {
    flex: $i !important;
  }

  .flex-shrink-#{$i} {
    flex-shrink: $i !important;
  }
}

@for $i from 1 through 100 {
  $percent: #{$i}+'%';

  .calc-w-#{i} {
    width: calc(100% - #{$percent}) !important;
  }

  .width-#{$i} {
    width: $percent !important;
  }

  .height-#{$i} {
    height: $percent !important;
  }

  .rounded-#{$i} {
    border-radius: $percent !important;
  }


  .margin-#{$i} {
    margin: $percent !important;
  }

  .margin-t-#{$i} {
    margin-top: $percent !important;
  }

  .margin-r-#{$i} {
    margin-right: $percent !important;
  }

  .margin-b-#{$i} {
    margin-bottom: $percent !important;
  }

  .margin-l-#{$i} {
    margin-left: $percent !important;
  }

  .margin-x-#{$i} {
    margin-left: $percent !important;
    margin-right: $percent !important;
  }

  .margin-y-#{$i} {
    margin-top: $percent !important;
    margin-bottom: $percent !important;
  }

  .padding-#{$i} {
    padding: $percent !important;
  }

  .padding-t-#{$i} {
    padding-top: $percent !important;
  }

  .padding-r-#{$i} {
    padding-right: $percent !important;
  }

  .padding-b-#{$i} {
    padding-bottom: $percent !important;
  }

  .padding-l-#{$i} {
    padding-left: $percent !important;
  }

  .padding-x-#{$i} {
    padding-left: $percent !important;
    padding-right: $percent !important;
  }

  .padding-y-#{$i} {
    padding-top: $percent !important;
    padding-bottom: $percent !important;
  }

  .custom-size-dialog-#{$i} {
    &>.modal-dialog {
      width: $percent !important;
      padding: 50px 0 !important;
    }
  }
}