.dashboard {
  width: 100%;
  padding: 38px;

  .labors {
    width: 315px;
    padding: 20px 30px;
    margin-right: 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.15);
    border-radius: 15px;

    .title {
      margin-bottom: 16px;

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 135%;
        color: #5C5C5C;
      }
    }

    .count_labor {
      width: 159px;
      height: 159px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 15px solid #38B138;

      p {
        font-weight: 600;
        font-size: 32px;
        text-align: center;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 5px;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
    }
  }

  .setups {
    width: 993px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 18px;
  }

  .item {
    min-width: 250px;
    flex: 1 1 calc(33.333% - 15px);
    max-width: calc(33.333% - 15px);
    height: 120px;
    display: flex;
    background: #FFFFFF;
    padding: 18px 20px;
    box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.15);
    border-radius: 15px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      margin-right: 14px;
      width: 48px;
      height: 48px;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 135%;
      margin-bottom: 20px;
      color: #5C5C5C;

    }

    span {
      font-weight: 600;
      font-size: 30px;
      letter-spacing: -1px;
      color: #000000;
    }

    .land {
      flex: 1;
      max-width: calc(100% - 60px);
      box-sizing: border-box;

      .land-item {
        div {
          max-width: calc(100% - 16px);
          p.text-overflow-ellipsis {
            line-height: 43px;
            font-size: 30px;
            color: black;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .dashboard .setups {
    width: 890px;
  }
}

@media screen and (max-width: 1500px) {
  .dashboard .setups {
    width: 828px;
  }
}

@media screen and (max-width: 1280px) {
  .dashboard .setups {
    width: 700px;

    .item {
      flex: 1 1 calc(50% - 9px);
      max-width: calc(50% - 9px);
    }
  }
}