.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  width: 100%;
  height: 180px;
  border: 2px dashed #ecf0f1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .drag-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .file-upload-icon {
      color: #2D5BFF;
      font-size: 34px;
    }

    .title-upload-file {
      font-size: 18px;
    }
  }

  .ic-close{
    position: absolute;
    top: 15px;
    right: 15px;
  }
  img {
    width: 50px;
    height: 50px;
    display: none;
  }

  .preview {
    margin-top: 20px;
    position: relative;
    left: -50px;
    width: 50px;
    height: 50px;
  }
}