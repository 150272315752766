.setup_content{
  width: 100%;
  height: 100%;
  .tab_header{
    width: 100%;
    height: 62px;
    border: 1px solid #E0E0E0;
    .tab{
      width: 12.5%;
      height: 100%;
      color: #009444;
      display: flex;
      border-right: 2px solid #e0e0e0;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:last-child{
        border: none;
      }
      img{
        margin-right: 8px;
      }
    }
    .tab.active{
      color: white;
      font-weight: 700;
      background: #009444;
    }
  }
}