.input-helper:before {
  border: 1px solid #333333 !important;
  border-radius: 4px;
}

.input-helper:after {
  background: #0f6ab9 !important;
}


.signUp_main {
  width: 100%;
  overflow: hidden;
  .step_2{
    .back_{
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .form-group.h-auto{
      height: 90px !important;
    }
  }

  h1{
    font-weight: 500;
    font-size: 36px;
    margin-bottom: 10px;
  }
  span.text_des{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5F6368;
  }
  .form_signUp {
    width: 735px;

    .logo_login {
      margin-bottom: 70px;
    }

    h2 {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 28px;
      font-family: Roboto;
      color: #000000;
    }

    label {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }

    .footer_action{
      margin-top: 25px;
      button {
        width: 220px;
        height: 46px;
        font-weight: 500;
        font-size: 16px;
      }
    }

  }

}
