#qr_code_tracking {
  .form {
    width: 100%;
    padding: 12px 28px 20px;
    background: #F8FDFF;

    .step_2 {
      width: 100%;

      .link_download_qr {
        width: 100%;
        padding: 10px 20px 20px;
        margin: 8px 0 16px;
        border: 1px solid rgba(0, 149, 61, 0.46);

        .action_ {
          input {
            width: 89%;
            height: 34px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 12px;
            margin-right: 12px;
            border: 1px solid #7C7B7B;
            border-radius: 2px;
          }

          button {
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}

#history_qr {

  .form {
    .tab_header {
      width: 100%;
      height: 40px;
      border: 1px solid #E0E0E0;

      .tab {
        flex: 1;
        height: 100%;
        color: #009444;
        display: flex;
        border-right: 2px solid #e0e0e0;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:last-child {
          border: none;
        }

        img {
          margin-right: 8px;
        }
      }

      .tab.active {
        color: white;
        font-weight: 700;
        background: #009444;
      }
    }

    width: 100%;
    display: inline-block;
    padding: 12px 28px 20px;
    background: #F8FDFF;

    .list_history {
      width: 100%;
      height: 312px;
      overflow-y: scroll;
      padding: 10px 20px;
      margin: 8px 0 16px;
      border: 1px solid rgba(0, 149, 61, 0.46);

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #7C7B7B;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .isExpired {
        opacity: 0.4;
      }

      .item {
        width: 100%;
        height: 58px;
        margin-bottom: 16px;

        span {
          font-size: 12px;
        }

        .action_ {
          margin-top: 6px;

          input {
            width: 89%;
            height: 34px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 12px;
            margin-right: 12px;
            border: 1px solid #7C7B7B;
            border-radius: 2px;
          }

          button {
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}

#qr_tem_demo {
  .form-group>label {
    margin-bottom: 4px !important;
  }

  .form-group>label,
  .label_check {
    color: #181818;
    font-weight: 400 !important;
  }
}

.tem_sample {
  position: relative;
  border: 1px solid #29AB56;
  border-radius: 4px;

  .close_img {
    position: absolute;
    right: -15px;
    top: -15px;
    width: 22px;
    height: 22px;
    display: flex;
    padding: 0 !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #de1010;
    z-index: 10;

    i {
      color: #de1010;
      font-size: 12px;
    }
  }

  img.tem_background {
    width: 100%;
    height: 100%;
  }

  #img_qrCode {
    position: absolute;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.upload_tem {
  position: relative;
  background-color: white;
  border-radius: 4px;
  display: flex;
  margin-right: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  border: 1px solid #57902B;

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    z-index: 1000;
    cursor: pointer !important;
    overflow: hidden;
    position: absolute;

    &::-webkit-file-upload-button {
      width: 100%;
      height: 100%;
      opacity: 0;
      left: 0;
      z-index: 1000;
      cursor: pointer !important;
      position: absolute;
    }
  }
}

.radio {
  .ant-radio-inner {
    background-color: white !important;

    &::after {
      background: #00953D;
      border-color: #00953D;
    }
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner {
    border-color: #00953D;
  }
}