.detail_farm__information {
  padding: 12px 48px 48px;
  .back {
    display: flex;
    margin-bottom: 48px;
    &:hover {
      opacity: 0.8;
    }

    i {
      margin-right: 8px;
      color: #5C5C5C;
      font-size: 20px;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #5C5C5C;
    }
  }
  h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #00953D;
    margin-bottom: 16px;
  }

  .info_unit{
    width: 100%;
    margin-bottom: 45px;

    .info_text{
      width: 100%;
      height: 38px;
      padding: 0 16px;
      display: flex;
      border-radius: 8px;
      align-items: center;
      label{
        margin: 0;
        width: 172px;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        height: 18px;
        color: #515154;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      span{
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        width: 396px;
        height: 24px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      &:first-child, &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9){
        background: #F5F5F7;
      }
    }
  }
  .scroll_certificate{
    width: 100%;
    height: 280px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 100%;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }


    .list_certificate{
      width: 100%;
      max-width: 2600px;
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;

      .item{
        width: 254px;
        height: 260px;
        margin-right: 24px;
        border: 1px solid #57902B;
        border-radius: 3px;
        background-color: white;
        img{
          width: 252px;
          height: 258px;
          object-fit: cover;
        }
      }
    }
  }
}
