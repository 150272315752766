
.action_wrapper{
  .action_icon__wrapper {
    padding: 4px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    background-color: #F5F5FA;
  }
}

ul.dropdown_wrapper {
  width: 180px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);


  .items {
    width: 100%;
    gap: 8px;
    display: flex;
    border: none;
    padding: 6px 12px;
    align-items: center;
    background-color: transparent;
    p {
      margin: 0;
      color: #626C70;
      font-size: 14px;
    }

  }
  .items.delete p, .items.lock p{
    color: red;
  }
  .items.success p, .items.open p{
    color: #00AB56;
  }
  .items.open p{
    color: #005CF7;
  }

}