.report{
  .header_back{
    label{
      margin: 0 6px 0 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #000000;
    }
    span{
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
      color: #000000;
    }
    span.text{
      margin-bottom: 10px;
    }
    .info_plan{
      width: 100%;
      height: 24px;
      margin-bottom: 12px;
      .info{
        height: 24px;
        padding: 0 24px;
        border-right: 1px solid #009444;
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          border-right: 0;
        }
      }
    }
  }

  .table_medicine, .table_fertilizer{
    .info_content{
      width: 100%;
      height: 99px;
      .images{
        width: 88px;
        height: 76px;
        margin-right: 28px;
        text-align: center;
        img{
          width: 48px;
          height: 48px;
          margin-bottom: 10px;
        }
        span{
          font-weight: 500;
          font-size: 14px;
          color: #00953D;
          text-transform: uppercase;
        }
      }
      .info_report{
        p{
          font-weight: 400;
          font-size: 14px;
          text-align: justify;
          color: #000000;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          span{
            font-weight: 700;
            font-size: 14px;
            display: inline-block;
            line-height: 20px;
            text-align: justify;
            margin-left: 6px;
            color: #000000;
          }
        }
      }
    }
  }
}