
.map_container {
  position: absolute;
  height: calc(100vh - 98px);
  width: 100%;
  padding: 0 !important;
}


.map_land_tracking .map_container {
  position: absolute;
  height: 460px;
  width: 100%;
  padding: 0 !important;
}

.full_screen {
  .map_container{
    height: 100%;
  }

  .zoom_map{
    left: 16px;
  }

  .zoom_full{
    top: 10px;
  }

  .locate{
    top: 58px;
  }
}

.controls-container {
  position: fixed;
  right: 0;
  top: 365px;
  width: 82px;
  padding: 26px 10px;
  background: #5C5C5C;
  border-radius: 16px 0 0 16px;

  .btn-control {
    width: 100%;
    border: none;
    display: flex;
    padding: 0;
    align-items: center;
    background-color: transparent;
    margin-bottom: 22px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;

    &:last-child {
      margin-bottom: 0;
    }

    img, i {
      width: 32px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin-right: 6px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

}

.gmnoprint {
  width: 170px !important;
  right: 61px !important;
  left: auto !important;
}

.gm-bundled-control{
  width: auto !important;
  left: 8px !important;
  right: auto !important;
  top: auto !important;
}

.zoom_map {
  position: fixed;
  left: calc(288px + 26px);
  width: 40px;
  height: 40px;
  border: none;
  background: #FFFFFF;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  i {
    color: #000000;
    font-size: 22px;
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.8;

    i {
      cursor: not-allowed !important;
      opacity: 0.8;
    }
  }
}

.zoom_plus {
  bottom: 98px;
}

.zoom_minus {
  bottom: 48px;
}

.locate, .zoom_full {
  top: 158px;
  position: fixed;
  right: 16px;
  height: 40px;
  width: 40px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border: none;
  background: #FFFFFF;
}

.zoom_full {
  top: 109px;
}

.bt_save {
  width: 158px;
  height: 96px;
  right: 0;
  position: fixed;
  bottom: 98px;
  border: none;
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  background: #00953D;
  border-radius: 100px 0px 0px 100px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  img {
    margin-right: 12px;
  }

}

.btn-initial-point {
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  border: 2px solid #303030;
  cursor: pointer;
}

.btn-delete-point {
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  border: 2px solid #303030;
  cursor: pointer;
  align-items: center !important;
  display: inline-flex;
}

.greatPlace {
  position: absolute;
  width: 100%;
  left: -60px;
  min-width: 250px;
  font-weight: 400;
  font-size: 16px;
  color: #FF3030;
}


.search_address {
  position: absolute;
  width: 645px;
  height: 86px;
  left: 0px;
  top: 0px;
  padding: 0 10px 10px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);

  label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    color: #000000;
  }

  .province {
    width: 33.3333%;
    padding-right: 24px;
    .input-group {
      width: 192px;
      height: 38px;
      select{
        width: 100% !important;
        height: 38px !important;
        padding: 0 16px;
        background: #FFFFFF !important;
        border: 1px solid #1A1A1A;
        border-radius: 2px;
      }
    }
  }
}
