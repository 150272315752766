.filter_header {
  width: 100%;
  height: 72px;
  padding: 0 16px;
  border: 1px solid #009444;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #00953D;
  }

  img {
    margin-right: 16px;
  }

  .action_filter {
    height: 38px;
    padding: 0 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #64646D;
    font-size: 15px;
    border: 1px solid transparent;
    position: relative;

    .number_action_filter {
      position: absolute;
      top: 0;
      right: 0;
      width: 14px;
      height: 16px;
      padding: 1px 4px;
      border-radius: 50%;
      background: #FF424E;
      color: white;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .form_search {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #009444;
      display: inline-block;
      margin-right: 6px;
    }

    .filter_date,
    .filter_select,
    .filter_search {
      width: 189px;
      height: 36px;

      input,
      select {
        width: 100%;
        height: 100%;
        padding: 0 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
        border-radius: 2px;
        border: 1px solid #009444;
        background-color: transparent;
      }

      i {
        top: 12px;
        right: 12px;
      }
    }

    .filter_search input {
      padding: 0 35px 0 12px;
    }
  }
}

.component_filter {
  label {
    font-weight: 500;
  }

  .ant-select.ant-select-single .ant-select-selector,
  .ant-picker {
    min-height: 46px;
    border-radius: 4px;
    border-color: #A6A6B0 !important;
  }
}