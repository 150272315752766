.table-sticky-header {
  thead {
    position: sticky;
    background: #F2F3F5 !important;
    z-index: 2;
    top: -2px;

    th,
    tr {
      position: sticky;
      z-index: 2;
      background: #F2F3F5 !important;
      top: 0px;
    }
  }

  tbody {
    position: relative;
    z-index: 1;
  }
}

.table td,
.jsgrid .jsgrid-table td {
  vertical-align: top !important;
}