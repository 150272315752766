.barcode-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding-left: 260px;

  .barcode-display-content {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 150px;

    .barcode {
      width: 100%;
      height: 100%;
      border: 1px dashed rgba(0, 149, 61, 1);
      display: flex;
      justify-content: center;
      align-items: center;

      .barcode-content {
        width: 80%;
        height: 80%;
        background-color: rgba(223, 223, 223, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        .no_barcode {
          padding: 20px 50px;
          text-align: center;

          .title {
            color: rgba(77, 77, 77, 1);
            font-weight: 400;
          }
        }
      }
    }

    .input_barcode {
      border-bottom: 1px solid rgba(215, 215, 215, 1);
      outline: none;
    }
  }
}


.scroll_certificate{
  width: 100%;
  height: 250px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 100%;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .list_certificate{
    width: 1900px;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    .upload{
      width: 220px;
      height: 220px;
      display: flex;
      margin-right: 12px;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer !important;
      border: 1px solid #57902B;
      border-radius: 3px;
      input {
        width: 220px;
        height: 220px;
        opacity: 0;
        left: 0;
        z-index: 1000;
        cursor: pointer !important;
        overflow: hidden;
        position: absolute;

        &::-webkit-file-upload-button {
          width: 220px;
          height: 220px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          position: absolute;
        }
      }
    }

    .item{
      width: 220px;
      height: 220px;
      position: relative;
      margin-right: 24px;
      border: 1px solid #57902B;
      border-radius: 3px;
      background-color: white;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .close_item{
        top: -12px;
        right: -12px;
        position: absolute;
        cursor: pointer;
      }
    }
  }

}

@media print {
  body * {
    visibility: hidden;
  }
  .barcode-content  * {
    visibility: visible !important;
  }
}