#update_tracking, #add_edit_tracking_qr {
  .modal-dialog {
    max-width: 905px;
  }

  .content_access {
    width: 100%;
    background: #F8FDFF;
    padding: 14px 14px;

    .barcode {
      width: 266px;
      height: 152px;
      display: flex;
      margin-right: 32px;
      margin-bottom: 5px;
      align-items: center;
      justify-content: center;
      border: 1px dashed #009444;
      padding: 12px 18px;
    }

    .input_barcode {
      width: 266px;
      height: 28px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      border: 1px solid #1A1A1A;
      border-radius: 0;

      &::placeholder {
        color: #D7D7D7;
      }
    }

    .no_barcode {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #DCDCDC;

      span {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
      }
    }

    .QR_code {
      width: 230px;
      height: 230px;
      display: flex;
      margin-right: 32px;
      align-items: center;
      justify-content: center;
      border: 1px dashed #009444;
    }

    .left{
      .state_product{
        width: 230px;
        label{
          font-weight: 500;
          font-size: 14px;
          color: #000000;
        }
        select, input{
          width: 100%;
          height: 32px;
          padding: 0 16px;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          margin-bottom: 0;
          margin-right: 12px;
          display: flex;
          align-items: center;
          border: 1px solid #1A1A1A;
          border-radius: 2px;
        }
      }

      .tem_demo{
        width: 230px;
        margin-top: 14px;

        button.add_{
          width: 110px;
          height: 28px;
          font-size: 14px;
          font-weight: 500;
          color: #009444;
          background-color: transparent;
          border: 1px solid #009444;
        }
        button.remove_{
          width: 110px;
          height: 28px;
          font-weight: 500;
          font-size: 14px;
          color: red;
          background-color: transparent;
          border: 1px solid red;
        }
        .tem_background{
          width: 3.5cm;
          height: 2.2cm;
          position: relative;
          .tem_img{
            width: 3.5cm;
            height: 2.2cm;
            position: relative;
          }
          #img_qrCode{
            width: 9mm;
            height: auto;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
          }
        }
      }
    }

    .form_access {

      .list_info_product {
        width: 100%;
        height: 330px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .info {
          width: 100%;

          .title_top {
            width: 100%;
            height: 38px;
            padding: 0 12px;
            display: flex;
            margin-bottom: 12px;
            align-items: center;
            justify-content: space-between;
            background: #009444;

            span {
              font-weight: 500;
              font-size: 14px;
              text-align: justify;
              text-transform: uppercase;
              color: #FFFFFF;
            }

            i {
              color: white;
              font-size: 18px;
            }
          }

          .list_content {
            width: 100%;

            .item {
              width: 100%;
              margin-bottom: 12px;
              display: flex;
              align-items: center;

              i {
                font-size: 18px;
              }

              input, label, select {
                height: 32px;
                padding: 0 16px;
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                margin-bottom: 0;
                margin-right: 12px;
                display: flex;
                align-items: center;
                border: 1px solid #1A1A1A;
                border-radius: 2px;
              }

              .time_range {
                height: 32px !important;

                i {
                  top: 7px;
                }
              }
              p.error{
                display: none !important;
              }
            }

            textarea {
              width: 100%;
              padding: 8px 16px;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              border: 1px solid #1A1A1A;
              border-radius: 2px;
            }
          }
        }

      }

      .save_qr_code {
        width: 100%;
        height: 48px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid #000000;
        margin: 14px 0 12px;

        .action_barcode {
          width: 115px;
          height: 28px;
          background-color: transparent;
          border: 1px solid #009444;
          border-radius: 2px;
          font-weight: 500;
          font-size: 14px;
          text-transform: capitalize;
          color: #009444;

          .up_img {
            width: 98px;
            height: 28px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
        }
      }
    }
  }

}

#update_tracking {
  .form_access {
    width: 525px;
    .list_info_product .info .list_content {
      .item {
        input, label {
          width: 45%;
        }
        .time_range {
          width: 45%;
        }
      }

    }
  }
}

#add_edit_tracking_qr {
  .form_access {
    width: 575px;
    .list_info_product .info .list_content {
      .item {
        input, label, select{
          width: 50%;
        }

        label.title, input.title {
          width: 40%;
        }

        .time_range {
          width: 50%;
        }
      }

    }
  }
}

#upload_images_tracking, #upload_images_seafood, #upload_images_livestock{
  .images_product {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 41px;

    .images {
      width: 354px;
      height: 208px;
      position: relative;
      border: 1px solid #00953D;
      border-radius: 3px;

      .img_ {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .no_image {
        width: 100%;
        height: 100%;
        display: flex;
        padding-top: 65px;
        justify-content: center;
        background: linear-gradient(0deg, #EFEFEF, #EFEFEF);

        img {
          width: 32px;
          height: 32px;
        }
      }

      .upload {
        width: 352px;
        height: 54px;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer !important;
        background: rgba(255, 255, 255, 0.75);

        input {
          width: 352px;
          height: 54px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;

          &::-webkit-file-upload-button {
            width: 352px;
            height: 54px;
            opacity: 0;
            left: 0;
            border-radius: 0 0 80px 80px;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }

        span {
          font-weight: 500;
          font-size: 16px;
          text-align: justify;
          color: #00953D;
        }

        img {
          margin-right: 12px;
        }
      }
    }
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
    color: #333333;
  }

  .list_image {
    width: 100%;
    height: 205px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 100%;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .list_ {
      min-width: 1250px;
      width: 100%;
      height: 194px;
      overflow: hidden;
      display: flex;
      align-items: center;

      .upload {
        width: 170px;
        height: 174px;
        display: flex;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer !important;
        border: 0.673077px solid #00953D;
        border-radius: 2px;

        input {
          width: 170px;
          height: 174px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;

          &::-webkit-file-upload-button {
            width: 170px;
            height: 174px;
            opacity: 0;
            left: 0;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }
      }

      .item {
        width: 170px;
        height: 174px;
        margin-right: 16px;
        position: relative;
        border: 0.673077px solid #00953D;
        border-radius: 2px;

        .img {
          width: 168px;
          height: 172px;
          object-fit: cover;
        }

        .close_item {
          top: -12px;
          right: -12px;
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .barcode  * {
    visibility: visible !important;
  }
}
