@import "./style/config.scss";

.body-wrapper {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 280px);
  background: #F8FDFF;
  // padding-bottom: 120px;

  .main-content {
    width: 100%;
    padding-left: 8px;
  }

}

.table-responsive {
  overflow-x: inherit !important;
}

select {
  cursor: pointer;
  background-color: transparent !important;
}

input,
select {
  border-radius: 2px;
}

input.form-control,
select.form-control {
  width: 100%;
  height: 48px !important;
  font-size: 14px !important;
  padding: 0 16px;
  color: #000000;

  &::placeholder {
    color: #858585;
  }
}

.ant-select.ant-select-single,
.ant-select.ant-select-multiple {
  width: 100%;
  min-height: 48px;

  .ant-select-selector {
    border-radius: 0.25rem;
    height: 46px;
    border: 1px solid #f3f3f3;
  }
}

.ant-select.border_error:hover .ant-select-selector {
  border-color: transparent !important;
}

.no_select__multi {
  width: 100%;
  height: 48px !important;

  .ant-select-selector {
    border-radius: 0.25rem;
    height: 46px !important;
  }
}

.ant-select-selection-search-input {
  height: 46px !important;
}

.ant-select-clear {
  top: 42% !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  line-height: 46px !important;
}


.time_range {
  width: 100%;
  height: 48px !important;
  font-size: 14px !important;

  input {
    width: 100%;
    font-size: 14px !important;
    cursor: pointer;
    padding-left: 12px;
    margin-left: 5px;
    border: 1px solid #f3f3f3;
  }

  i {
    top: 18px;
    right: 16px;
    position: absolute;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;

  .react-datepicker__input-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;

    input {
      padding: 0 16px;
      font-size: 14px;
      width: 100% !important;
      margin: 0;
      height: 100%;
      background-color: transparent;

      &:disabled {
        background-color: rgba(193, 193, 193, 0.6);
      }
    }
  }

}

.react-datepicker-popper {
  z-index: 1200 !important;
}


.btn-outline-green {
  color: #00953D;
  border: 1px solid #00953D;
  background-color: transparent;
}

.bt_map {
  width: 136px;
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00953D;
  border-radius: 2px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;

  img {
    margin-right: 6px;
  }

  &:hover {
    color: white;
  }
}

.date_error input {
  border: 1px solid red !important;
}

table tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

table td {
  padding: 0.6rem 0.75rem !important;
  font-size: 14px !important;
  border-top: none !important;
}

.table thead th {
  border-bottom: none !important;
}



textarea.form-control {
  width: 100%;
  font-size: 14px !important;
  padding-left: 16px;
  padding-right: 16px;
  color: #000000;

  &::placeholder {
    color: #858585;
  }
}


.error {
  font-size: 13px;
  color: red;
  display: inline-block;
  width: 100%;
  height: 20px;
  padding-top: 4px;
}

.border_error {
  border: 1px solid red !important;

  &:focus-visible,
  &:focus {
    outline: none !important;
  }
}


.text-success {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #009444;
}

.color-green {
  color: #009444 !important;
}

.w-ratio {
  width: 228px !important;
}

.input-password {
  i {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #000000;
    font-size: 18px;
    z-index: 1000;
  }
}


.form-group {
  height: 102px;
  margin-bottom: 0 !important;
}

#popup_detail_job {
  .modal-dialog {
    max-width: 1172px;

    .detail_plan {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 44px;

      &:first-child span {
        border-top: 1px solid #D9D9D9;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        width: 189px;
        min-height: 44px;
        background: #ECF0FF;
        border-bottom: 1px solid white;
      }

      span {
        width: 930px;
        min-height: 44px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 0 16px;
        border-bottom: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;
      }
    }
  }
}

.bg-green {
  background-color: #009444 !important;
}

.bt-outline-action {
  max-width: 85px;
  min-width: 56px;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #1E9A57;
  padding: 0 8px;
  background: #FFFFFF;
  border: 1.5px solid #1E9A57;
  border-radius: 4px;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.bt-action-delete {
  max-width: 85px;
  min-width: 56px;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ED2121;
  background: #FFFFFF;
  border: 1.5px solid #ED2121;
  border-radius: 4px;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.bt_remove {
  color: #FFFFFF;
  background: #ED2121;
  border: 1.5px solid #ED2121;

  &:hover {
    color: #FFFFFF;
  }
}


.button_global {
  width: 180px;
  height: 42px;
  padding: 4px 12px;
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  background: white;
  color: rgba(0, 148, 68, 1);
  border-radius: 4px;
  border: 2px solid rgba(0, 148, 68, 1);

  .title-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 148, 68, 1) !important;
    margin-left: 8px;
  }
}

.modal_full {
  padding: 0 !important;

  .modal-dialog {
    margin: 0 !important;
    padding: 32px 0;
    max-width: 100% !important;
    height: 100vh;
    background-color: white;

    .modal-content {
      padding: 0 100px;

      .row {
        width: 100%;
        height: calc(100vh - 240px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #6b6a6a;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #6b6a6a;
        }
      }
    }

    button.close {
      width: 92px;
      height: 32px;
      right: 16px;
      top: -18px;
      justify-content: center;
      position: absolute;
      background: #F4F4F4;
      border-radius: 4px;
      opacity: 1;
      z-index: 1060;

      span {
        line-height: 14px;
        cursor: pointer;
      }

      span,
      p {
        height: 20px;
        color: #000000;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        padding-left: 5px;
      }
    }

    .modal-body {
      label {
        display: inline-block;
        width: 256px;
        margin-bottom: 0;
      }

      .input-group,
      .time_range {
        width: calc(100% - 256px);
      }

      span {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
    }
  }

  .item_info {
    align-items: center;
    background-color: white;
    border-bottom: 8px solid #f8f8f8;
  }

}

.bt-back-outline {
  width: 354px;
  height: 50px;
  border: 1px solid #29AB56;
  background: transparent;
  border-radius: 2px;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  color: #29AB56;
  margin: 0;
  text-decoration: none;
}

.send-request {
  width: 246px;
  height: 50px;
  border: none;
  background: #29AB56;
  border-radius: 2px;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;

    background: #21984A;
  }
}

.mce-notification-inner,
.mce-close,
.mce-notification-warning {
  display: none !important;
}

.send-request-outline {
  width: 246px;
  height: 50px;
  background: transparent;
  border-radius: 2px;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #29AB56;
  text-decoration: none;
  border: 1px solid #29AB56;

  &:hover {
    color: #29AB56;
    background: transparent;
  }
}

.bt_remove_outline {
  width: 100%;
  height: 100%;
  background: transparent !important;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red !important;
  border: 1px solid red !important;

  &:hover {
    color: red;
    background: transparent;
  }
}


button:disabled {
  cursor: no-drop;
}

.filter_search {
  width: 189px;
  height: 36px;

  input,
  select {
    width: 100%;
    height: 100%;
    padding: 0 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
    border-radius: 2px;
    border: 1px solid #009444;
    background-color: transparent;
  }

  i {
    top: 12px;
    right: 12px;
  }
}

.modal {
  .modal-dialog {
    max-width: 798px;
  }

  label {
    font-weight: 500;
  }

  .form-group {
    height: 102px;
  }

  .modal-header {
    border: none;

    h5 {
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      color: #333333;
    }

    .close {
      opacity: 1 !important;

      span {
        font-size: 26px !important;
        font-weight: 400;
      }
    }
  }

  .modal-footer {
    border: none;
    padding-left: 26px !important;
    padding-right: 26px !important;
    padding-bottom: 32px !important;
  }


}

.pagination .page-item .page-link {
  border-color: #E0E0E0;
}


.pagination .page-item.active .page-link {
  background: #29AB56;
  border-color: #29AB56;
}

.page-link:hover {
  background: #29AB56 !important;
  border-color: #29AB56 !important;
}

table th {
  padding: 1.5rem 0.9375rem !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  background: #ECF0FF;
  border-right: 1px solid white;

  &:last-child {
    border-right: none;
  }
}


table td {
  line-height: 22px !important;
  max-width: 350px;
  white-space: normal !important;

  p.des {
    width: 100%;
    height: 40px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}


.table_land {
  padding: 0;
}

.bt_add {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 12px 20px;
}

.button_add {
  width: auto;
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00953D;
  border-radius: 2px;
  border: none;

  i {
    color: white;
    font-size: 18px;
    margin-right: 6px;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }

  strong {
    font-weight: 600 !important;
  }
}

.button_add.text-white {

  i,
  span {
    color: white !important;
  }
}

button:disabled {
  opacity: 0.6;
}


#popup_success {
  .modal-dialog {
    width: 408px;
    height: 196px;
    background: #FFFFFF;
    border-radius: 8px;

    .modal-content {
      border: none;

      .modal-header {
        padding: 19px 19px 0 !important;
      }

      .icon {
        margin-bottom: 24px;
      }

      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #29AB56;
        margin-bottom: 0;
      }
    }
  }
}

#popup_confirm,
#popup_confirm_comment,
#modal_confirm {
  .modal-dialog {
    width: 600px;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;

    .modal-content {
      border: none;

      .modal-header {
        padding: 19px 19px 0 !important;
      }

      .icon {
        margin-bottom: 24px;
      }

      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #333333;
        margin-bottom: 32px;
      }

      .action {
        margin-bottom: 32px;

        button {
          width: 264px;
          height: 44px;
          font-weight: 700;
          font-size: 14px;
          border-radius: 2px;
          margin: 0 12px;
        }

        button.btn-cancel {
          border: 1.5px solid #858585;
          color: #858585;
          background-color: transparent;
        }

        button.btn-confirm {
          color: white;
          background: #29AB56;
        }
      }
    }
  }
}

.btn_action_qr {
  width: 51px;
  height: 51px;
  border: none;
  padding: 6px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: #009444;

  .btn_ac {
    width: 100%;
    height: 100%;
    border: 0.653846px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.table td .action,
.MuiTable-root td .action {
  display: flex;
  align-items: center;
  justify-content: center;

  button.bt_action {
    border: none;
    background-color: transparent;
    padding: 6px;

    &:focus-visible,
    &:focus {
      outline: none;
    }

    a {
      display: inline-block;
      margin-right: 2px;
    }

  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 0;
    cursor: pointer;
  }
}

.header_back {
  width: 100%;
  height: 72px;
  padding: 22px 16px;
  border: 1px solid #009444;

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #009444;
    margin-right: 6px;
    display: inline-block;
  }

  i {
    color: #009444;
    margin-right: 6px;
    font-size: 18px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #009444;
  }

  a.back {
    display: flex;

    i {
      margin-right: 8px;
      color: #009444;
      font-size: 20px;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #009444;
    }
  }
}

a.link {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #009444;
}

.btn-action {
  width: 38px;
  height: 38px;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: 1px solid #D7D7D7;
  border-radius: 3px;

  span {
    color: #acacac;
    font-size: 28px;
  }

  &:hover .list_content {
    opacity: 1;
    display: block;
    animation-duration: 0.5s;
    animation-name: showEffect;
  }

  .list_content {
    position: absolute;
    z-index: 1000;
    top: 36px;
    padding-top: 10px;
    right: -24px;
    opacity: 0;
    display: none;
    width: 140px;
    height: auto;

    .action {
      width: 140px;
      height: auto;
      background: #F5F5F5;
      border-radius: 3px 3px 0px 0px;
      transition: 0.5s;
      box-shadow: 0px 0px 15px rgba(12, 47, 76, 0.15);

      &::before {
        border-width: 14px;
        border-style: solid;
        border-color: transparent transparent rgb(255, 255, 255);
        border-image: initial;
        margin-left: 15px;
        top: -16px;
        left: 50%;
        content: "";
        height: 0px;
        width: 0px;
        position: absolute;
        pointer-events: none;
      }

      &:last-child {
        button.bt {
          border: none;
        }
      }

      button.bt {
        max-width: 140px;
        width: 140px;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        border: none;
        text-decoration: none;
        display: flex;
        border-radius: 0;
        align-items: center;
        padding: 10px 24px 10px 16px;
        border-bottom: 1px solid #eeeeee;

        &:hover {
          background: #F7F8FF;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
  }
}

@keyframes showEffect {
  from {
    opacity: 0;
    display: none;
  }

  to {
    opacity: 1;
    display: block;
  }
}

.filter_header {
  width: 100%;
  height: 72px;
  padding: 0 16px;
  border: 1px solid #009444;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #00953D;
  }

  img {
    margin-right: 26px;
  }

  .form_search {
    margin-right: 24px;
    height: 36px;

    &:last-child {
      margin-right: 0;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #009444;
      display: inline-block;
      margin-right: 12px;
    }

    .filter_date,
    .filter_select,
    .filter_search {
      width: 189px;
      height: 36px;

      .input-group {
        height: 100%
      }

      input,
      select {
        width: 100%;
        height: 100% !important;
        padding: 0 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
        border-radius: 2px;
        border: 1px solid #009444;
        background-color: transparent;
      }

      i {
        top: 12px;
        right: 12px;
      }
    }

    .filter_search input {
      padding: 0 35px 0 12px;
    }
  }
}

.basic-multi-select {

  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 48px !important;
    border: 1px solid #f3f3f3;
  }

  #react-select-4-placeholder {
    font-size: 14px;
  }
}

.tox-tinymce {
  width: 100%;
  border: 1px solid #eee !important;

  .tox-statusbar {
    display: none !important;
  }
}

.mce-container-body.mce-flow-layout span.mce-label {
  font-size: 14px !important;
}

select {
  &:disabled {
    background-color: #e9ecef !important;
  }
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.cursor_pointer {
  cursor: pointer;
}

.modal.popupZoom {
  .close_ {
    width: 44px;
    height: 44px;
    padding: 10px;
    border: 0;
    right: -150px;
    top: 40px;
    z-index: 1200;
    cursor: pointer !important;
    border-radius: 4px;
    position: absolute;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(30, 30, 30, .6);

    &:hover {
      cursor: pointer !important;
    }

    i {
      color: #FFFFFF;
      font-size: 32px;
      cursor: pointer;
    }
  }

  .modal-dialog {
    max-width: 1250px;
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;

    .modal-content {
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
      background-color: transparent;

      .carousel {
        width: 100%;
        height: 100%;

        .carousel-inner {
          width: 100%;
          height: 100%;
          border-radius: 6px;

          .carousel-item {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              cursor: zoom-in;
              border-radius: 6px;
              transition: transform .3s;
              object-fit: scale-down;
              transform: scale(1);
            }

            img.zoomOut {
              cursor: zoom-out;
              transform: scale(1.4);
            }
          }
        }

        .carousel-control-prev,
        .carousel-control-next {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 48%;

          i {
            color: #FFFFFF;
            font-size: 50px;
          }

          &:hover {
            i {
              color: #00953D;
            }
          }
        }

        .carousel-control-prev {
          left: -150px;
        }

        .carousel-control-next {
          right: -150px;
        }

      }
    }
  }
}


.download_app {
  z-index: 100;
  right: 16px;
  bottom: 42px;
  display: flex;
  width: 100%;
  justify-content: center;

  .app_ios,
  .app_android {
    bottom: 60px;
  }

  .app_ios {
    right: 210px;
  }

  .app_android {
    right: 45px;
  }
}

input.input_table {
  text-align: center;
  height: 32px !important;
}

.text_import {
  font-size: 14px;
  color: #00953D;
  font-weight: 500;
}

.text_export {
  font-size: 14px;
  color: #F69320;
  font-weight: 500;
}

.bt_history {
  width: 80px;
  height: 40px;
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #EEEEEE;
}

.scroll_file {
  width: 100%;
  height: 600px;
  padding-bottom: 40px;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #7C7B7B;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.react-pdf__Document {
  position: absolute;
  top: 0;
}



.import_data {
  position: relative;
  width: auto;
  height: 40px;

  input {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 40px;
    opacity: 0;
  }
}

.bg_export {
  background-color: #F69320 !important;
}

.upLoadImage img,
.upLoadImage .no_img {
  width: 220px;
  height: 130px;
  object-fit: scale-down;
}

.upLoadImage img {
  border: 1px solid #e7e7e7;
}

.upLoadImage .no_img {
  background-color: #e5e5e5;
}

.upLoadImage .no_img i {
  font-size: 20px;
  color: #989898;
}


.upLoadImage .change-avatar {
  width: 96px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 82px 0 0 25px;
  border-radius: 6px;
  position: relative;
  cursor: pointer !important;
  border: 1px solid #0f6ab9 !important;
}

.upLoadImage .change-avatar span {
  color: #0f6ab9;
  font-size: 14px;
  display: inline-block;
  width: 100%;
}

.ant-switch.ant-switch-checked {
  background: #00953D !important;
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00953D !important;
  border-color: #00953D !important;
}

.upLoadImage .change-avatar input {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 96px;
  height: 42px;
  cursor: pointer !important;
  position: absolute;
}

.enterprise_page{

  .modal {
    h5 {
      color: #27272A;
      font-weight: 700;
      text-transform: uppercase;
    }

    h4.title {
      color: #27272A;
      font-weight: 500;
      font-size: 14px;
    }

    .header_info {
      width: 100%;
      border-radius: 6px;
      padding: 6px 12px;
      margin-bottom: 16px;
      background-color: #F1FAFF;
    }

    .action_footer {
      margin-top: 20px;

      button {
        width: 218px;
        height: 38px;
        font-weight: 500;
        border-radius: 6px;
        border: none;
      }

      .bt_cancel {
        color: #373737;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F9F9F9;
      }

      .bt_approve {
        color: white;
        background-color: #009444;
      }

      .bt_reject {
        color: white;
        background-color: #FF424F;
      }

      .bt_canceled {
        color: white;
        background-color: #1F3A58;
      }
    }
  }

  .bt-reject {
    color: red;
    border-radius: 6px;
    border: 1px solid red;

    &:hover {
      border: 1px solid red !important;
    }
  }

  .bt-approve {
    color: green;
    border-radius: 6px;
    border: 1px solid green;

    &:hover {
      border: 1px solid green !important;
    }
  }
}



@media screen and (max-width: 1600px) {
  .body-wrapper {
    width: calc(100% - 260px);
  }
}


@media screen and (max-width: 1500px) {
  .body-wrapper {
    width: calc(100% - 250px);
  }
}

@media screen and (max-width: 425px) {

  .modal.popupZoom {
    .close_ {
      width: 32px;
      height: 32px;
      right: 15px;
      top: 40px;

      i {
        font-size: 24px;
      }
    }

    .modal-dialog {
      padding-top: 120px;
      height: 75vh;

      .modal-content {
        .carousel {
          height: 80%;

          .carousel-control-prev,
          .carousel-control-next {
            width: 50px;
            height: 50px;
            top: 45%;

            i {
              font-size: 25px;
            }
          }

          .carousel-control-prev {
            left: -25px;
          }

          .carousel-control-next {
            right: -25px;
          }

        }
      }
    }
  }

}

button.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  min-width: 150px;
  height: 48px;
  line-height: 24px;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 4px;

  &.btn-cancel {
    color: black;
    background: #E6E6E6;
  }

  &.btn-confirm {
    color: white;
    background: linear-gradient(0deg, #00953D, #00953D),
      linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }
}

.overflow-y {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.overflow-x {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.ant-typography-copy {
  font-size: 20px;
}

.table-sticky-header {
  overflow: auto;

  thead {
    position: sticky;
    background: #F2F3F5 !important;
    z-index: 2;
    top: -3px;

    th {
      position: sticky;
      z-index: 2;
      background: #F2F3F5 !important;
      top: 0px;
    }
  }

  tbody {
    position: relative;
    z-index: 1;
  }
}

.is-expired {
  background: #F3F4F5;
  height: calc(100vh - 150px) !important;

  .order-buy-package {
    max-height: 275px !important;
  }

  .list-rq-code {
    max-height: calc(100vh - 485px) !important;
  }

  .table-history-order {
    max-height: calc(100vh - 250px) !important;
  }
}

@media screen and (min-width: 1600px) {
  .is-expired {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1599px) {
  .is-expired {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media screen and (max-width: 1499px) {
  .is-expired {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

.text-overflow-ellipsis {
  display: -webkit-box;
  line-height: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 100%;
}

button.button_outline {
  background-color: white !important;
  border: 1px solid #009444 !important;
  color:#009444 !important;
  font-weight: 500;
}