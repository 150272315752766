.profile{
  .tab_active{
    width: 100%;
    height: 72px;
    margin-bottom: 36px;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    span{
      width: 50%;
      height: 100%;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      color: #009444;
    }
    span.active{
      color: #FFFFFF;
      background: #009444;
    }
  }

  .change_pass {
    width: 100%;
    min-height: 500px;
    padding: 34px;
    background: #FFFFFF;
    .form-group{
      width: 640px;
      label{
        margin: 0 0 12px 0;
        width: 640px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: block;
      }
      input{
        width: 640px;
      }
    }
    button{
      width: 175px;
    }
  }

  .change_account{
    width: 100%;
    padding: 42px;
    background: #FFFFFF;
    .avatar_user{
      width: 152px;
      height: 152px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      img.avatar{
        width: 152px;
        height: 152px;
        object-fit: cover;
      }

      .upload{
        width: 152px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        cursor: pointer !important;
        background: rgba(255, 255, 255, 0.6);

        input{
          width: 152px;
          height: 50px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;
          &::-webkit-file-upload-button{
            width: 152px;
            height: 50px;
            opacity: 0;
            left: 0;
            border-radius: 0 0 80px 80px;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }

      }
      span{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #07294D;
      }
    }

    .form{
      margin-left: 46px;
      .form-group{
        width: 602px;
        margin-bottom: 22px !important;
        p{
          margin: 0;
          width: 126px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #010414;
        }
        input.form-control{
          width: 475px;
        }
        .time_range{
          width: 475px;
        }
      }
      button{
        width: 175px;
      }
    }
  }

}