.warehouse_page{
  width: 100%;
  height: 100%;

  .tab_header{
    width: 100%;
    height: 62px;
    border: 1px solid #E0E0E0;
    .tab{
      width: 20%;
      height: 100%;
      color: #009444;
      display: flex;
      border-right: 2px solid #e0e0e0;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:last-child{
        border: none;
      }
      img{
        margin-right: 8px;
      }
    }
    .tab.active{
      color: white;
      font-weight: 700;
      background: #009444;
    }
  }
}

.back_log{
  width: 100%;
  height: 60px;
  span{
    font-size: 20px;
    font-weight: 600;
    color: #00953D;
    padding-bottom: 5px;
  }
  button{
    width: 115px;
    height: 40px;
    font-size: 16px;
    color: #00953D;
    padding: 0 !important;
    border: 1px solid #00953D;
    &:hover{
      color: #00953D;
    }
  }
  i{
    font-size: 20px;
    color: #00953D;
  }

}

.warehouse_page {
  table tr{
    height: 56px;
  }
}

.table_job {
  width: 100%;
  padding: 0;
  min-height: 220px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  tbody tr td:last-child {
    width: 8%;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #popup_detail_log * {
    visibility: visible !important;
  }
  #close_detail_log, .modal-footer {
    display: none !important;
  }
}
