#view-history-order-farm {
    .frame-rq {
        position: relative;

        .rq-bank-information {
            position: absolute;
            top: 12px;
            left: 10px;
            border-radius: 10px;
        }
    }
}