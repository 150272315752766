.notification-expired {
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 48px;
  background: #1D2129;
  color: #F7F8FA;
  padding: 0 16px;
  flex-wrap: wrap;

  button {
    border-radius: 4px;
    margin-left: 8px;
    height: 36px;
  }

  .ant-statistic {
    .ant-statistic-content-value {
      font-size: 16px;
      color: white;
      line-height: 48px;
    }
  }

}

table.table-expense {
  width: 100%;

  &,
  th,
  td {
    font-size: 20px !important;
  }

  thead {
    background: #F2F2F2;
    border-bottom: 1px solid #F6F8FF
  }

  td,
  th {
    font-weight: 600;
    padding: 10px 12px !important;
  }

  td {
    font-weight: 600;
  }

  th {
    font-weight: 600;
    padding: 10px 12px !important;
  }

  .radio {
    .ant-radio-inner {
      width: 18px;
      height: 18px;
      margin: 0 4px;
      background-color: white !important;

      &::after {
        width: 10px;
        height: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #00953D;
        border-color: #00953D;
        margin: auto;
      }
    }

    .ant-radio-wrapper {
      margin: 0;
    }

    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
    .ant-radio-wrapper:hover .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner {
      border-color: #00953D;
      margin: 0 4px;
    }
  }
}

.text-header {
  color: #008100;
  font-size: 28px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
}

.text-end {
  text-align: end;
}

.item-package {
  background: #f3f3f3;
  border-radius: 12px;
  flex: 1 1 calc(25% - 15x);
  max-width: calc(25% - 15px);
  min-width: 285px;

  .btn-confirm,
  .btn-cancel {
    border-radius: 100px;
    width: 224px;
    text-transform: uppercase;
  }

  &.item-recommended {
    background: #004C73;
    color: white;

    &.item-using {
      background: none;
      color: black;
    }

    .recommended {
      margin: 0;
      font-size: 10px;
      width: 74px;
      min-width: 70px;
      line-height: 22px;
      border-radius: 75px;
      background: #F97414;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-confirm.button:hover {
    background: #047934;
  }
}

.buy-package,
#upgrade-farm-modal {
  table {

    th,
    td {
      padding: 13px !important;
    }

    .quatity {
      width: calc(100% - 70px);

      input {
        text-align: center;
        padding: 4px;
      }
    }

    .btn-quatity {
      min-width: 30px;
      padding: 0 6px;

      span {
        line-height: 24px;
        padding-bottom: 3px;
      }
    }
  }

  .item-resources {
    min-width: 240px;
    flex: 1 1 calc(33.333% - 7px);
    max-width: calc(33.333% - 7px);
    box-sizing: border-box;
    background: #f3f3f3;
    border-radius: 12px;
    height: 106px;

    .icon-add {
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.07);
      }
    }
  }
}

.bank-infomation {
  padding-top: 57px;
  display: flex;
  justify-content: center;

  .dashed-line {
    margin-right: auto;
    height: 1px;
    width: 80%;
    border-top: 2px dashed #000;
  }

  .frame-rq {
    position: relative;

    .rq-bank-information {
      position: absolute;
      top: 12px;
      left: 10px;
      border-radius: 10px;
    }
  }
}

#package_in_use_modal {
  .modal-dialog {
    font-size: 14px;
    width: 410px;
    position: relative;

    .detail_frame {
      width: 410px;
    }

    .modal-content {
      position: absolute;
      top: 22px;
      left: 22px;
      width: calc(100% - 41px);
      border-radius: 10px;

      .package-description {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px solid #00953D;
        border-radius: 6px;
        gap: 8px;
        padding: 12px;

      }
    }
  }
}

.overflow-x,
.overflow-y,
.overflow-auto {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 10px !important;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      background-color: #9e9e9e;
    }
  }
}

.buy-package {
  @media (min-height: 1100px) {
    .order-buy-package {
      max-height: 250px !important;
    }

    .list-rq-code {
      height: 250px;
      flex-wrap: wrap !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }

  @media (min-height: 1000px) and (max-height: 1099px) {
    .order-buy-package {
      max-height: 250px !important;
    }

    .list-rq-code {
      height: 218px;
      flex-wrap: wrap !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }
}

#upgrade-farm-modal {
  @media (max-height: 700px) {
    
    .list-rq-code {
      height: 225px;
      min-height: 225px;
      flex-wrap: wrap !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }
}