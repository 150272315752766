.info_main{
  width: 100%;
  height: 100%;
  padding: 16px 30px 50px;
  background: #FFFFFF;
  .edit-info{
    width: 110px;
    span{
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #5c5c5c;
    }
    i{
      font-size: 20px;
      color: #5C5C5C;
      margin-right: 12px;
      display: inline-block;
    }
  }
  h2{
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 16px;
    color: #00953D;
  }


  .info_unit{
    width: 100%;
    margin-bottom: 45px;

    .info_text{
      width: 100%;
      height: 38px;
      padding: 0 16px;
      display: flex;
      border-radius: 8px;
      align-items: center;
      label{
        margin: 0;
        width: 172px;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #515154;
      }

      span{
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
      &:first-child, &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9){
        background: #F5F5F7;
      }
    }
  }
  .input-group{
    width: calc(100% - 172px) !important;
  }
  .form-info{
    margin-bottom: 16px;
    label{
      margin: 0;
      width: 172px;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #515154;
    }
  }

  .scroll_certificate{
    width: 1555px;
    height: 290px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 100%;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .list_certificate{
      width: 1900px;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;

      .upload{
        width: 254px;
        height: 260px;
        display: flex;
        margin-right: 24px;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer !important;
        border: 1px solid #57902B;
        border-radius: 3px;
        input {
          width: 254px;
          height: 260px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;

          &::-webkit-file-upload-button {
            width: 254px;
            height: 260px;
            opacity: 0;
            left: 0;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }
      }

      .item{
        width: 254px;
        height: 260px;
        position: relative;
        margin-right: 24px;
        border: 1px solid #57902B;
        border-radius: 3px;
        background-color: white;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .close_item{
          top: -12px;
          right: -12px;
          position: absolute;
          cursor: pointer;
        }
      }
    }

  }

  .bt-request{

  }
}