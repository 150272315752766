.notfound {
  width: 100%;
  height: 100vh;

  img{
    width: 280px;
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    margin: 35px 0 20px;
    color: #1D1D1F;
  }
  span{
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    color: #515154;
  }
  a{
    margin-top: 60px;
    width: 320px;
    height: 60px;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
  }
}