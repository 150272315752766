.account_manager {
  h2 {
    color: #00953D;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.106px; /* 117.255% */
    text-transform: uppercase;
  }
}